<script>
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'question-select-buttons',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    local: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },
  methods: {
    setValue(val) {
      this.local = val
      this.$emit('next')
    },
  },
}
</script>

<template>
  <div class="mb-[5px] flex flex-wrap justify-center px-11">
    <base-button
      v-for="(action, i) in content.actions"
      :key="i"
      class="mx-[5px] mb-[15px] min-w-40"
      @click="setValue(action.value)"
    >
      {{ action.name }}
    </base-button>
  </div>
</template>
