<script>
export default {
  name: 'health-datepicker',
  props: {
    value: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      step: 'day',
      date: {
        day: null,
        month: null,
        year: null,
      },
    }
  },
  computed: {
    days() {
      return Array.from(Array(31), (v, i) => i + 1).map((value) => ({
        label: value < 10 ? `0${value}` : value,
        value,
      }))
    },
    monthes() {
      const names = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      return names.map((label, value) => ({ value, label }))
    },
    years() {
      const max = new Date().getFullYear() - 18

      return Array.from(Array(100), (v, i) => max - i)
    },
  },
  methods: {
    onSelectDay(day) {
      this.date.day = day
      this.step = 'month'
    },
    onSelectMonth(month) {
      this.date.month = month
      this.step = 'year'
    },
    onSelectYear(year) {
      this.date.year = year
      this.step = null
      this.onChange()
    },
    onChange() {
      const date = new Date(
        this.date.year,
        this.date.month.value,
        this.date.day.value,
        0,
        0,
        0
      )

      this.$emit('input', date)
    },
    onStepClick(type) {
      if (this.date[type]) {
        this.step = type
      }
    },
  },
}
</script>

<template>
  <div
    class="mx-auto mb-[30px] w-full max-w-96 border-separate rounded-xl border-2"
  >
    <div
      class="px-[30px] py-2.5 text-center text-lg font-bold text-fg-disabled"
    >
      <span
        :class="{
          'text-black': date.day,
          'text-primary': step === 'day',
          'cursor-pointer': step !== 'day' && date.day,
        }"
        @click="onStepClick('day')"
      >
        {{ date.day ? date.day.label : 'DD' }}
      </span>
      <span>/</span>
      <span
        :class="{
          'text-black': date.month,
          'text-primary': step === 'month',
          'cursor-pointer': step !== 'month' && date.month,
        }"
        @click="onStepClick('month')"
      >
        {{ date.month ? date.month.label : 'MM' }}
      </span>
      <span>/</span>
      <span
        :class="{
          'text-black': date.year,
          'text-primary': step === 'year',
          'cursor-pointer': step !== 'year' && date.year,
        }"
        @click="onStepClick('year')"
      >
        {{ date.year || 'YYYY' }}
      </span>
    </div>
    <div
      v-if="step"
      class="max-h-96 border-separate overflow-auto border-t-2 px-2.5 py-2.5 sm:px-11"
    >
      <template v-if="step === 'day'">
        <div class="my-2.5 text-center font-bold">Select day</div>
        <div class="flex flex-wrap">
          <div
            v-for="(day, idx) in days"
            :key="`day-${idx}`"
            class="w-1/6 cursor-pointer py-[15px] text-center text-sm font-bold"
            :class="[day === date.day && 'rounded bg-primary text-white']"
            @click="onSelectDay(day)"
          >
            {{ day.label }}
          </div>
        </div>
      </template>

      <template v-if="step === 'month'">
        <div class="my-2.5 text-center font-bold">Select month</div>
        <div class="flex flex-wrap">
          <div
            v-for="(month, idx) in monthes"
            :key="`month-${idx}`"
            class="w-1/3 cursor-pointer py-[15px] text-center text-sm font-bold"
            :class="[month === date.month && 'rounded bg-primary text-white']"
            @click="onSelectMonth(month)"
          >
            {{ month.label }}
          </div>
        </div>
      </template>

      <template v-if="step === 'year'">
        <div class="my-2.5 text-center font-bold">Select year</div>
        <div class="flex flex-wrap">
          <div
            v-for="(year, idx) in years"
            :key="`year-${idx}`"
            class="w-1.5 cursor-pointer py-[15px] text-center text-sm font-bold"
            :class="[year === date.year && 'rounded bg-primary text-white']"
            @click="onSelectYear(year)"
          >
            {{ year }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
