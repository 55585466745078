<script>
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import EntryDatePicker from './entries/date-picker.vue'
import EntryNumeric from './entries/numeric.vue'
import EntryRadio from './entries/radio-form.vue'
import EntrySelectActions from './entries/select-actions.vue'
import EntrySelectButtons from './entries/select-buttons.vue'
import EntrySelectMultiple from './entries/select-multiple.vue'

export default {
  name: 'question-entry',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: undefined,
    }
  },
  computed: {
    type() {
      return this.content.type
    },
    required() {
      return this.content.required
    },
    entryComponent() {
      if (this.type === 'date-picker') return EntryDatePicker
      else if (this.type === 'select') return EntrySelectActions
      else if (this.type === 'select-buttons') return EntrySelectButtons
      else if (this.type === 'select-multiple') return EntrySelectMultiple
      else if (this.type === 'radio') return EntryRadio
      else if (this.type === 'numeric') return EntryNumeric
      return null
    },
  },
  methods: {
    next(v) {
      this.value = undefined
      this.$emit('next', v)
    },
  },
}
</script>

<template>
  <div class="flex flex-col items-center">
    <component
      :is="entryComponent"
      v-if="entryComponent"
      v-model="value"
      :content="content"
      :class="{
        'justify-center px-11': type === 'select-multiple',
      }"
      @next="next(value)"
    />
    <base-button v-if="!required" look="link" @click="next(null)">
      Skip question
    </base-button>
    <base-button
      v-else-if="Array.isArray(value) ? value.length > 0 : value"
      look="link"
      class="mt-5"
      @click="next(null)"
    >
      Next question
      <base-icon
        svg="plain/chevron-right"
        size="md"
        class="ml-[15px] text-xs"
      />
    </base-button>
  </div>
</template>
