<script>
import { useAssessment } from '/~/extensions/health/composables'
import BaseButton from '/~/components/base/button/base-button.vue'
import SelectMultiple from './questions/entries/select-multiple.vue'

export default {
  name: 'tags',
  components: {
    SelectMultiple,
    BaseButton,
  },
  setup() {
    const { tags, userTags, syncUserAnswers } = useAssessment()

    return {
      tags,
      userTags,
      syncUserAnswers,
    }
  },
  data() {
    return {
      value: {
        Body: [],
        'Food Recipes': [],
        'Health Articles': [],
        Mind: [],
      },
    }
  },
  methods: {
    onConfirm() {
      this.userTags = this.value
      this.syncUserAnswers()
      this.$emit('confirm')
    },
  },
}
</script>

<template>
  <div class="mx-2.5 my-12 flex max-w-2xl flex-col">
    <h1 class="my-2.5 text-center">Congratulations!</h1>

    <p class="mb-[30px] text-center">
      You've officially complited your Health Assessment.
      <br />
      Be sure to select your areasof interests and conserns to receive access
      <br />
      to personalised content!
    </p>

    <template v-for="key in Object.keys(tags)">
      <div :key="key" class="mb-2.5">
        <h4 class="mb-2.5 font-bold">{{ key }}:</h4>

        <select-multiple
          :value="value[key]"
          :content="{
            actions: tags[key].map((name) => ({ name, value: name })),
          }"
          @input="value[key] = $event"
        />
      </div>
    </template>

    <div class="flex w-full justify-center">
      <base-button class="my-10 w-full max-w-xs" @click="onConfirm">
        Confirm
      </base-button>
    </div>
  </div>
</template>
